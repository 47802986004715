
import EntryStatus from './EntryStatus.vue'
import { isImage, isPdf, isAudio, isVideo } from '../../utils'

export default {
	name: 'Asset',
	components: { EntryStatus },
	inject: [ 'endpoint', 'eventBus', 'defaultLocale' ],
	props: {
		locale: String,
		selectable: Boolean,
		asset: Object,
	},
	data: () => ({
		loading: 0,
		model: null,
		error: false,
	}),
	methods: {
		// TODO: loading here may lead to MANY requests.
		//       we may rather use AssetList in the ReferencesField and load there
		//       OR maybe better: we should have a general loading-facility (take the one from the RTE?)
		//          and group the parallel loads into bulks
		async loadAsset() {
			if (!this.asset?.sys?.id) return console.warn('no asset id', this.asset)
			this.loading++
			const request: any = {}
			request['sys.id'] = this.asset.sys.id
			const entries = await this.$httpGet(this.endpoint + '/assets', request)
			this.model = entries.items[0]
			this.loading--
		},
		onError(e) {
console.log('Asset.onError', e)
			this.$emit('error', e)
			this.error = true
		},
		isImage,
		isPdf,
		isAudio,
		isVideo,
	},
	async mounted() {
		if (!this.asset?.sys?.updatedAt) {
			await this.loadAsset()
		}
		else {
			this.model = this.asset
		}
		const id = this.asset?.sys?.id
		if (id) {
			this.eventBus.$on('reloadAsset_' + id, async () => {
				await this.loadAsset()
			})
		}
	},
}
