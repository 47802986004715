
import { field } from './FieldMixin.js'
import { applyReactInVue } from 'vuereact-combined'
import { RichTextEditor } from '@contentful/field-editor-rich-text'
import Dialog from '../Dialog.vue'
import EntryPicker from './EntryPicker.vue'
import AssetPicker from './AssetPicker.vue'
import { loadEntries as EA_loadEntries, loadAssets as EA_loadAssets } from '../../EntryApi'
import { emptyRteValue, emptyRteValueLength } from '../../utils'
import Info from '../../views/Info.vue'

// TODO: take formatting options from the field def and set it on the editor

// DOCS
// VueReact https://github.com/devilwjp/vuereact-for-vuecli3-demo
// CFRTE https://contentful-field-editors.netlify.app/?path=/docs/editors-rich-text-editor--docs
// CFRTE demo https://github.com/contentful/field-editors/blob/5bbca863106912d6db500943b9bf77983059f29d/packages/rich-text/stories/RichTextEditor.stories.tsx
// CFRTE https://github.com/contentful/field-editors/blob/75fc49210a5789bf029ddb81413349d74f441f28/packages/rich-text/src/RichTextEditor.mdx
// CFRTE https://raw.githubusercontent.com/contentful/field-editors/75fc49210a5789bf029ddb81413349d74f441f28/packages/rich-text/src/RichTextEditor.mdx

function debug(...args) {
//	console.log('RTF.debug', ...args)
}

// CFRTE fake sdk https://github.com/contentful/field-editors/blob/5bbca863106912d6db500943b9bf77983059f29d/cypress/fixtures/FakeSdk.ts#L16
// see https://github.com/contentful/field-editors/blob/2e5ab489b11c26fe9ba8c1c011ca4f1cfc248845/packages/rich-text/stories/RichTextEditor.stories.tsx#L123
// TODO: i only grew this sdk object until no exceptions came up at smoke-testing.
//       probably this object needs more!
//       https://github.com/contentful/ui-extensions-sdk/blob/b2d917fb7f261a27a00661486594d8d0e2f5ac25/lib/types/api.types.ts
// contentful comps need the sdk to work.
// there is a way to "run locally" and probably we could also mock/facade the sdk in some way
function newSdk(vm, locale) {

	const getEventPoint = (name: any) => {
		return (...args: any) => {
			debug('eventHandler', name, args)
			// when the user deleted the last character, we get a removeValue event instead of a value update..
			// this is weirdly complicated syntax, but i couldnt get anything else to work..
			if (name == 'removeValue') return new Promise(function (resolve, reject) { vm.setValueEmpty(); resolve(null) })

			// TODO: we just do nothing here - this may create issues..
			return (...args2) => {
				debug('RTE eventHandler run', name, args, args2)
			}
		}
	}

	// we only use this facility to reverse engineer which members are needed
	const newDebugProxy = (name: string, o: any) => {
		return new Proxy(o, {
			apply(...args: any[]) { debug(name + '.apply', args) },
			construct(...args: any[]) { debug(name + '.construct', args); return o },
			get(t: any, n: string, ...args: any[]) {
				if (t[n]) return t[n]
				if (n?.startsWith?.('__v_')) return
				debug(name + '.get', 'target:', t, 'name:', n, 'args:', args)
			},
			has(...args: any[]) { debug(name + '.has', args); return true },
		})
	}

	const sdk = newDebugProxy('sdk', {
		entry: {
			getSys: () => ({ id: '123' }),
		},
		locales: newDebugProxy('locales', {
			default: locale,
			direction: [],
		}),
		field: {
			// empty node: ({nodeType: document, data: {}, content: []}),
			getValue: undefined,
			setValue: undefined,
			onSchemaErrorsChanged: getEventPoint('onSchemaErrorsChanged'),
			onIsDisabledChanged: getEventPoint('onIsDisabledChanged'),
			onValueChanged: getEventPoint('onValueChanged'),
			removeValue: getEventPoint('removeValue'),
			locale,
			validations: undefined,
		},
		ids: {
			space: 'S1',
		},
		space: {
			onEntityChanged: undefined, /* ATT: implemented further down */
			getEntry: undefined,
			getAsset: undefined,
			getCachedContentTypes: () => [
				...Object.values(window['typeLookup']),
				{ sys: { id: 'MISSING' }, name: 'ENTRY IS MISSING OR INACCESSIBLE', description: '', fields: [ { id: 'title', localized: false } ], displayField: 'title' },
			],
			// TODO: i think this was moved to use makeRequest..
			getEntityScheduledActions: async () => ([]),
		},
		navigator: {
			onSlideInNavigation: getEventPoint('onSlideInNavigation'),
		},
		dialogs: {
			selectSingleEntry: undefined,
			selectSingleAsset: undefined,
		},
		cmaAdapter: newDebugProxy('cmaAdapter', {
			makeRequest: undefined, /* ATT: implemented further down */
		}),
		parameters: {
			instance: {
				useLocalizedEntityStatus: false,
			},
		},
	})
	return sdk
}

// cache for the entry contentType ids
const entryType = {}

export default {
	name: 'RichTextField',
	components: { RichTextEditor: applyReactInVue(RichTextEditor), Dialog, EntryPicker, AssetPicker, Info },
	mixins: [ field ],
	inject: [ 'endpoint', 'defaultLocale', 'locales', 'eventBus', 'entryLoader' ],
	props: {
		value: Object,
		def: Object,
		title: String,
		disabled: { type: Boolean, default: false },
		locale: String,
	},
	data() {
		const sdk = newSdk(this, this.locale)

		sdk.field.getValue = () => this.value
		sdk.field.setValue = (value) => {
			debug('field.setValue', value)
			this.$emit('input', value)
			return new Promise(function (resolve, reject) { resolve(null) })
		}
		// TODO: we currently dont have 'nodes' validations - is this a bug in FieldSettings?
		/*{ nodes: {
			'asset-hyperlink': [ { size: { min: 0, max: 5 }, message: null } ],
			'embedded-asset-block': [ { size: { min: 0, max: 4 }, message: null } ],
			'embedded-entry-block': [ { linkContentType: ['address'], message: null }, { size: { min: 0, max: 3 }, message: null ],
			'embedded-entry-inline': [ { linkContentType: ['address'], message: null }, { size: { min: 0, max: 6 }, message: null } ],
			'entry-hyperlink': [ { linkContentType: ['address'], message: null }, { size: { min: 0, max: 2 }, message: null } ]
		}}*/
		sdk.field.validations = this.def.validations

		// TODO: refactor loading!

		const sleep = ms => new Promise(r => setTimeout(r, ms))
		let entryIds: string[] = []
		// we synchronize all calls on this promise
		let loadingEntries: Promise<{ [index: string]: any }>
		const loadEntries = async () => {
			// we wait some time before starting the request, so some ids can be collected
			await sleep(400)
			const ids = entryIds
			entryIds = [] as string[]
			// allow for a second/... run
			loadingEntries = undefined
			const entries = await (this as any).loadEntries(ids)
			const entryLookup = {}
			for (const entry of entries) {
				entryLookup[entry.sys.id] = entry
				;(this as any).entityCache[entry.sys.id] = entry
				entryType[entry.sys.id] = entry.sys.contentType?.sys?.id
			}
			// we have to manually validate here
			// because the previous validation may have happened on incomplete information (missing types)
			;(this as any).validate()
			return entryLookup
		}
		let assetIds: string[] = []
		// we synchronize all calls on this promise
		let loadingAssets: Promise<{ [index: string]: any }>
		const loadAssets = async () => {
			// we wait some time before starting the request, so some ids can be collected
			await sleep(600)
			const ids = assetIds
			assetIds = [] as string[]
			// allow for a second/... run
			loadingAssets = undefined
			const assets = await (this as any).loadAssets(ids)
			const assetLookup = {}
			for (const asset of assets) {
				assetLookup[asset.sys.id] = asset
				;(this as any).entityCache[asset.sys.id] = asset
			}
			;(this as any).validate()
			return assetLookup
		}

		// id => listener(data)
		const entryUpdateListeners = {}
		// this registers listeners for entry updates
		sdk.space.onEntityChanged = (type, id, listener) => {
			if (type == 'Entry' || type == 'Asset')
				entryUpdateListeners[id] = listener
			// return an unsubscriber
			return () => { delete entryUpdateListeners[id] }
		}
		sdk.space.getEntry = async (entryId: string) => {
			debug('space.getEntry', entryId)
			entryIds.push(entryId)
			if (!loadingEntries)
				loadingEntries = loadEntries()
			const lookup = await loadingEntries
			const entry = lookup[entryId]
			if (!entry) return { sys: { id: entryId, type: 'Entry', deletedVersion: 1, contentType: { sys: { id: 'MISSING', linkType: 'contentType', type: 'Link' } }, fields: {} } }
			return entry
		}
		sdk.space.getAsset = async (assetId: string) => {
			debug('space.getAsset', assetId)
			assetIds.push(assetId)
			if (!loadingAssets)
				loadingAssets = loadAssets()
			const lookup = await loadingAssets

			let asset = lookup[assetId]
			if (!asset) return { sys: { id: assetId, type: 'Asset', deletedVersion: 1, contentType: { sys: { id: 'MISSING', linkType: 'contentType', type: 'Link' } }, fields: {} } }
			// TODO: this should take actual fallback chains into account.
			if (!asset.fields?.file) return asset
			asset = JSON.parse(JSON.stringify(asset))
			const dl = (this as any).defaultLocale
			for (const locale of (this as any).locales) {
				if (!asset.fields.file[locale.code])
					asset.fields.file[this.locale] = asset.fields.file[dl]
			}
			return asset
		}
		sdk.space.getEntries = async (search: { content_type: string, query: string }) => {
			debug('space.getEntries', search)
			// TODO: what about pagination or search? we dont seem to get any other input than the content_type
			if (search.content_type == 'contentHubAsset') {
				const filter = {
					search: search.query,
					filters: [],
					order: [ '-sys.updatedAt' ],
				}
				return await EA_loadAssets((this as any).endpoint, filter, 20, 0)
			}
			else {
				const filter = {
					contentType: search.content_type,
					search: search.query,
					filters: [],
					fields: [ 'title' ],
					order: [ '-sys.updatedAt' ],
				}
				return await EA_loadEntries((this as any).endpoint, filter, 20, 0, 0, 'RTE.slash')
			}
		}
		sdk.cmaAdapter.makeRequest = async ({ action: string, entityType, params: { entryId, assetId, environmentId, spaceId } }) => {
			debug('cmaAdapter.makeRequest', { action: string, entityType, params: { entryId, assetId, environmentId, spaceId } })

			if (entityType == 'Entry') 
				return sdk.space.getEntry(entryId)

			if (entityType == 'Asset')
				return sdk.space.getAsset(assetId)

			// silently return empty results for now
			if (entityType == 'ScheduledAction')
				return { items: [] }

			// TODO: what else may be loaded?
		}
		sdk.navigator.openEntry = (id: string, options: any) => {
			debug('navigator.openEntry', id, options)
			this.$emit('subedit', { id, type: 'Entry' }, async () => {
				const _this = (this as any)
				_this.eventBus.$emit('reloadEntry_' + id)
				const fresh = await _this.loadEntries([ id ])
				if (!fresh?.[0]) return
				_this.entryUpdateListeners[ id ]?.(fresh[0])
				;(this as any).eventBus.$emit('refresh', fresh[0], this)
			})
		}
		sdk.navigator.openAsset = (id: string, options: any) => {
			debug('navigator.openAsset', id, options)
			this.$emit('subeditAsset', { id, type: 'Asset' }, async () => {
				const _this = (this as any)
				_this.eventBus.$emit('reloadAsset_' + id)
				const fresh = await _this.loadAssets([ id ])
				if (!fresh?.[0]) return
				_this.entryUpdateListeners[ id ]?.(fresh[0])
				;(this as any).eventBus.$emit('refresh', fresh[0], this)
			})
		}
		sdk.dialogs.selectSingleEntry = (...args: any[]) => {
			debug('selectSingleEntry', ...args)
			const _this = (this as any)
			let mode = (args[0]?.entityType) ? _this.entryPickerMode : 'link'
			return (this as any).openEntryPicker(mode)
		}
		sdk.dialogs.selectSingleAsset = (...args: any[]) => {
			debug('selectSingleAsset', ...args)
			return (this as any).openAssetPicker()
		}
		return {
			sdk,
			entryFilter: { contentType: null, search: '', filters: [] },
			entryPickerResolve: null,
			entryPickerReject: null,
			assetFilter: { contentType: null, search: '', filters: [] },
			assetPickerResolve: null,
			assetPickerReject: null,
			entryUpdateListeners,
			allowedContentTypes: null,
			entryPickerMode: null,
			entityCache: {},
		}
	},
	watch: {
		value(n) {
			this.validate()
		},
	},
	methods: {
		/*
		// TODO: what is even the difference betwene an asset-hyperlink and an embedded-asset-block?
					how to do this in the ui?
					same for entry..
		{
			"enabledMarks":["bold","italic","underline","code"],
			"enabledNodeTypes":["heading-1","heading-2","heading-3","heading-4","heading-5","heading-6","ordered-list","hr","blockquote","embedded-entry-block","embedded-asset-block","table","hyperlink","entry-hyperlink","asset-hyperlink","embedded-entry-inline","unordered-list"],
			"nodes":{
				"asset-hyperlink":[
					{"size":{"min":0,"max":5},"message":null}
				],
				"embedded-asset-block":[
					{"size":{"min":0,"max":4},"message":null}
				],
				"embedded-entry-block":[
					{"linkContentType":["address"],"message":null},
					{"size":{"min":0,"max":3},"message":null}
				],
				"embedded-entry-inline":[
					{"linkContentType":["address"],"message":null},
					{"size":{"min":0,"max":6},"message":null}
				],
				"entry-hyperlink":[
					{"linkContentType":["address"],"message":null},
					{"size":{"min":0,"max":2},"message":null}
				]
			}
		}
		*/
		validate(value = undefined) {
			if (value === undefined) value = this.value
			const errors = []
			const warnings = []
			const traverse = (o, cb, index = undefined, path = '/', maxDepth = 10) => {
				const r = cb(o, index, path)
				if (r === false) return
				if (maxDepth == 0) return
				if (typeof o != 'object') return
				for (const i in o) {
					traverse(o[i], cb, i, path + i + '/', maxDepth -1)
				}
			}
			const EEB = { validation: this.validations.nodes?.['embedded-entry-block'], count: 0 }
			const EEI = { validation: this.validations.nodes?.['embedded-entry-inline'], count: 0 }
			const EAB = { validation: this.validations.nodes?.['embedded-asset-block'], count: 0 }
			const EH = { validation: this.validations.nodes?.['entry-hyperlink'], count: 0 }
			const checkType = (tracker, o) => {
				tracker.count++
				const entryId = o.data?.target?.sys?.id
				const linkType = o.data?.target?.sys?.linkType
				const cachedEntry = this.entityCache[entryId]
				if (!cachedEntry)
					warnings.push(`${ linkType } ${ entryId } is missing or inaccessible.`)

				const type = entryType[entryId]
				const allowed = tracker.validation?.linkContentType?.value
				if (allowed && type && !allowed.includes(type))
					errors.push(tracker.validation?.linkContentType.message ?? `Content type ${ type } not allowed. Use any of ${ allowed.join(', ') }.`)
				return false
			}
			traverse(value, (o, i, p) => {
				if (o?.nodeType == 'embedded-entry-block') return checkType(EEB, o)
				if (o?.nodeType == 'embedded-entry-inline') return checkType(EEI, o)
				if (o?.nodeType == 'embedded-asset-block') return checkType(EAB, o)
				if (o?.nodeType == 'entry-hyperlink') return checkType(EH, o)
			}, 10)
			const checkCount = (tracker, typeName) => {
				const size = tracker.validation?.size
				if (size?.min && tracker.count < size.min)
					errors.push(size.message ?? `At least ${ size.min } ${ typeName } required.`)
				if (size?.max && tracker.count > size.max)
					errors.push(size.message ?? `At most ${ size.max } ${ typeName } allowed.`)
			}
			checkCount(EEB, 'entries')
			checkCount(EEI, 'inline entries')
			checkCount(EAB, 'assets')
			checkCount(EH, 'entry hyperlinks')

			// HACK: this length check is a bit hacky..
			if (this.validations?.required && (!this.value || JSON.stringify(this.value).length == emptyRteValueLength))
				errors.push('Required')

			this.onErrors([
				...errors,
				// TODO: we will need to do min and max differently i think..
				//this.validateMax(),
				//this.validateMin(),
			])
			this.onWarnings(warnings)
		},
		debug(action: string, ...args: any[]) {
			debug('DEBUG', ...args)
		},
/*		async loadEntry(id) {
			const request: any = {}
			request['sys.id'] = id
			const entries = await this.$httpGet(this.endpoint + '/entries', request)
			return entries.items[0]
		},*/
		// TODO: we should actually only load a minimal entry here..
		async loadEntries(ids) {
			const request: any = {}
			request['sys.id[in]'] = ids.join(',')
			const entries = await this.$httpGet(this.endpoint + '/entries', request)
			return entries.items
		},
		async loadAssets(ids) {
			const request: any = {}

			// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
			// TODO: remove this workaround when this is fixed at the server!
			ids = [ ...ids, ...ids.map(id => 'asset/' + id) ]

			request['sys.id[in]'] = ids.join(',')
			const assets = await this.$httpGet(this.endpoint + '/assets', request)
			for (const asset of assets.items) {
				asset.sys.id = asset.sys.id.replace(/^asset\//, '')
				this.entityCache[asset.sys.id] = asset
			}
			this.validate()
			return assets.items
		},
		async openEntryPicker(mode: 'block' | 'inline' | 'link') {
			// TODO: set the allowedContentTypes
			// validations.linkContentType
			// TODO: empty -> all
			//console.log('>>>>>> EP', mode, this.validations, this.def)
			if (mode == 'block')
				this.allowedContentTypes = this.validations?.nodes?.['embedded-entry-block']?.linkContentType?.value
			if (mode == 'inline')
				this.allowedContentTypes = this.validations?.nodes?.['embedded-entry-inline']?.linkContentType?.value
			if (mode == 'link')
				this.allowedContentTypes = this.validations?.nodes?.['entry-hyperlink']?.linkContentType?.value

			this.$refs.addEntry.open()
			return new Promise((resolve, reject) => {
				this.entryPickerResolve = resolve
				this.entryPickerReject = reject
			})
		},
		onEntryPickerInput(selection) {
			this.entryPickerResolve?.(selection[0])
			this.$refs.addEntry.close()
			// TODO: trigger change event upwards for autosave
		},
		async onEntryPickerCreate(entry) {
			this.entryPickerResolve?.(entry)
			this.$refs.addEntry.close()
			/*
			TODO: instead of the current solution we should refactor to a global event bus + loader:
			bus
				.entryLoad(type, id)
				.entryLoaded(type, id, entry)
			in general store / loader
				on entryLoad
				-> load entry
				-> emit entryLoaded
			in any comp
				on entryLoaded
				-> copy model (?)
				-> update local model
			*/
			// TODO: we make sure a reloadEvent is fired, but we dont actually listen to these events yet
			//       we should listen and then update the added entry titles somehow
			//       how? i think the RTE has an entry store - can we use that?
			this.$emit('subedit', entry.sys, async () => {
				console.log('SUB EDIT COMPLETE')
				this.eventBus.$emit('reloadEntry_' + entry.sys.id)
				const fresh = await this.loadEntries([ entry.sys.id ])
				if (!fresh?.[0]) return
				this.entryUpdateListeners[entry.sys.id]?.(fresh[0])
				this.eventBus.$emit('refresh', fresh[0], this)
			})
			// TODO: trigger change event?
		},
		onEntryPickerOk(selection) {
			// TODO: do we want to support a multi-add? how? does CF?
			// TODO: for each?
		},
		onEntryPickerCancel() {
			this.entryPickerResolve?.()
			this.$refs.addEntry.close()
		},
		async openAssetPicker() {
			this.$refs.addAsset.open()
			return new Promise((resolve, reject) => {
				this.assetPickerResolve = resolve
				this.assetPickerReject = reject
			})
		},
		onAssetPickerInput(selection) {
			debug('onAssetPickerInput', selection)
			this.assetPickerResolve?.(selection[0])
			this.$refs.addAsset.close()
			// TODO: trigger change event upwards for autosave
		},
		async onAssetPickerCreate(asset) {
			console.log('onAssetPickerCreate', asset)
			this.assetPickerResolve?.(asset)
			this.$refs.addAsset.close()
			this.$emit('subeditAsset', asset.sys, async () => {
				this.eventBus.$emit('reloadAsset_' + asset.sys.id)
				const fresh = await this.loadAssets([ asset.sys.id ])
				if (!fresh?.[0]) return
				this.entryUpdateListeners[asset.sys.id]?.(fresh[0])
				this.eventBus.$emit('refresh', fresh[0], this)
			})
		},
		onAssetPickerOk(selection) {
			// TODO: do we want to support a multi-add? how? does CF?
		},
		onAssetPickerCancel(r) {
			debug('onAssetPickerCancel', r)
			this.assetPickerReject()
		},
		setValueEmpty() {
			this.$emit('input', JSON.parse(JSON.stringify(emptyRteValue)))
		},
		// ADJ-22632 hack-workaround for (likely) slate bug: when opening as stacked editor like this:
		// http://localhost:8081/spaces/g8e1xze8wimq/environments/master/entries/2iswtRDKXSA80JQuptmXqu?stack=4j6ckryL8o0yRgJLR1tYzi,1H3rlgwssHXrkd3g7h2ufA%29
		// the link-popovers dont work.
		fixLinkPopover(e) {
			const href = e.srcElement?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.href
			document.querySelectorAll('div[data-cf-ui-portal] > div').forEach((el: any) => {
				const contentHref = el.querySelector('a')?.href
				if (!contentHref) return
				el.style.display = (href && href == contentHref) ? 'block' : 'none'
			})
		},
		// this hack detects which embed menu item was clicked and updates the mode accordingly
		// we need this to know about the entry type restrictions
		// note that we can not detect the 'link' type, because its somewhere else in the ui,
		// that mode is detected in sdk.dialogs.selectSingleEntry.
		entryPickerModeDetector(e) {
			// only run this when clicking on a link-span
			// (not when clicking on the popover itself, otherwise this will cancel the action)
			if (e.target.tagName != 'SPAN') return

			this.fixLinkPopover(e)
			// TODO: optimise - not for all clicks?

			// which button was clicked?
			let el = e.target as HTMLElement
			while (el && el.tagName != 'BUTTON') el = el.parentElement
			if (!el) return

			const classList = el.classList
			const block = classList?.contains?.('rich-text__embedded-entry-block-list-item')
			const inline = classList?.contains?.('rich-text__entry-link-block-button')
			if (block) this.entryPickerMode = 'block'
			if (inline) this.entryPickerMode = 'inline'
			if (block || inline) console.warn('SETTING MODE', this.entryPickerMode)
		},
	},
	async mounted() {
		this.$nextTick(() => {
			if (!this.value)
				this.setValueEmpty()
		})
		document.addEventListener('mousedown', this.entryPickerModeDetector)
		this.eventBus.$on('refresh', (entry, source) => {
			if (!entry?.sys?.id) return
			this.entityCache[entry.sys.id] = entry
			this.validate()

			if (source == this) return
			const listener = this.entryUpdateListeners[entry?.sys?.id]
			if (!listener) return
			listener(entry)
		})
	},
	beforeDestroy() {
		document.removeEventListener('mousedown', this.entryPickerModeDetector)
		this.eventBus.$off('refresh')
	},
}
