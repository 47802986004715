<template>
	<div class="FieldView" v-if="field">
		<!-- TODO: show locale in title if the field is localizable -->
		<h3>{{ field.name }} <span v-if="locale">- {{ locale }}</span></h3>

		<div v-if="field.type == 'Symbol' || field.type == 'Text' || field.type == 'Number'">
			{{ value }}
		</div>

		<div v-if="field.type == 'Integer' && control.widgetId != 'rating'">
			{{ value }}
		</div>
		<div v-if="field.type == 'Integer' && control.widgetId == 'rating'">
			<RatingField :title="field.name" :def="field" :control="control" v-model="value" :disabled="true" />
		</div>

		<div v-if="field.type == 'Link' && field.linkType == 'Entry'">
			<Entry :entry="value" v-if="value">
				<template #actions>&nbsp;</template>
			</Entry>
		</div>
		<div v-if="field.type == 'Array' && field.items.linkType == 'Entry'">
			<div v-for="entryLink of value" :key="entryLink.sys.id">
				<Entry :entry="entryLink" v-if="value">
					<template #actions>&nbsp;</template>
				</Entry>
			</div>
		</div>

		<div v-if="field.type == 'Link' && field.linkType == 'Asset'">
			{{value}}
			<Asset :asset="value">
				<template #actions>&nbsp;</template>
			</Asset>
		</div>
		<div v-if="field.type == 'Array' && field.items.linkType == 'Asset'">
			<div v-for="entryLink of value" :key="entryLink.sys.id">
				<Asset :asset="entryLink" v-if="value">
					<template #actions>&nbsp;</template>
				</Asset>
			</div>
		</div>

		<div v-if="field.type == 'Array' && field.items.type == 'Symbol'">
			<ul>
				<li v-for="item of value ?? []" :key="item">{{ item }}</li>
			</ul>
		</div>

		<div v-if="field.type == 'RichText'">
			<RichTextField v-if="field.type == 'RichText'" :title="field.name" :def="field" v-model="value" :disabled="true" />
		</div>

		<div v-if="field.type == 'Date'">
			{{ value ? new Date(value) : '' }}
		</div>

		<div v-if="field.type == 'Location'">
			<LocationField :def="field" :control="control" v-model="model" :disabled="true" />
		</div>

		<div v-if="field.type == 'Object'">
			<pre>{{ JSON.stringify(value, null, 4) }}</pre>
		</div>

		<div v-if="field.type == 'Boolean'">
			{{ value === true ? trueLabel : value === false ? falseLabel : '' }}
		</div>

		<div v-if="field.type == 'File'">
			TODO: {{ value }}
			<!--
			<FileField v-if="field.type == 'File'" :title="field.name" :def="field" :control="control" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
			-->
		</div>
	</div>
</template>

<script>
import Entry from './Entry.vue'
import Asset from './Asset.vue'
import RichTextField from './RichTextField.vue'
import RatingField from './RatingField.vue'
import LocationField from './LocationField.vue'

export default {
	name: 'FieldView',
	components: { Entry, Asset, RichTextField, RatingField, LocationField },
	props: {
		control: Object,
		entry: Object,
		field: Object,
		value: [ String, Number, Object, Array, Boolean ],
		locale: String,
	},
	computed: {
		trueLabel() {
			return this.control?.settings?.trueLabel ?? 'Yes'
		},
		falseLabel() {
			return this.control?.settings?.falseLabel ?? 'No'
		},
	},
}
</script>

<style scoped>
.FieldView { width: 100%; pointer-events: none; }
.FieldView * { word-break: break-all; }
h3 { margin: 0; padding: 0; color: rgb(90, 101, 124); font-weight: normal; font-size: 14px; margin-bottom: 10px; }
</style>